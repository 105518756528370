import React from "react";

import Loud from "../loud/loud";
import Nav from "../nav/nav";
import "./header.scss";

const Header = () => (
  <header className="site-header">
    <Nav />

    <Loud />

    <div id="background">
			<svg viewBox="0 0 1510 851" xmlns="http://www.w3.org/2000/svg">
			  <g fill-rule="nonzero" fill="none">
				 <g fill="#C3F365">
					<path d="M1409 655.5a12.1 12.1 0 0117-.1v.1l33.5 33.4 33.4-33.4 17.1 17-42 42a12.1 12.1 0 01-17 .1v-.1l-33.5-33.4-33.4 33.4-17.1-17 42-42zM1329 727.5a12.1 12.1 0 0116.9-.1l.1.1 33.5 33.4 33.4-33.4 17.1 17-42 42a12.1 12.1 0 01-17 .1v-.1l-33.5-33.4-33.4 33.4a12.1 12.1 0 01-17 .1l-.1-.1-33.5-33.4-33.4 33.4-17.1-17 42-42a12.1 12.1 0 0117-.1v.1l33.5 33.4 33.4-33.4z" />
				 </g>
				 <path d="M299.5 161c39 0 70.9 31.6 71.5 70.8v1.2h-24.2a47.5 47.5 0 00-47.3-47.6c-25.9 0-46.9 20.9-47.3 46.8v.8H228c0-39.8 32-72 71.5-72zm167 0c39 0 70.9 31.6 71.5 70.8v1.2h-24.2a47.5 47.5 0 00-47.3-47.6c-25.9 0-46.9 20.9-47.3 46.8v.8H395c0-39.8 32-72 71.5-72z" fill="#FFD44B"/>
				 <path d="M1291.5 340a71.5 71.5 0 011.2 143h-1.2v-24.2a47.3 47.3 0 10-47.3-48v.7H1220c0-39.5 32-71.5 71.5-71.5z" fill="#2FD5C7"/>
				 <path d="M159.5 678c10.7 0 16 12.9 8.7 20.6l-.2.2L17.1 851 0 833.8l130.2-131.4H66.6V678h92.9z" fill="#FFA829"/>
				 <path fill="#FC5454" d="M1097.5 411v105.5h-81v81h-81v81H830v-25h80.5v-81h81v-81h81V411z"/>
				 <path d="M899.9 27.5c67.2-36.6 123.5-37 166.5.5l1.3 1.2-16.7 18.6c-33.9-30.4-78.8-30.7-137.4.7l-1.8 1c-67.2 36.6-123.6 37-166.5-.5l-1.3-1.2 16.7-18.6c33.9 30.4 78.8 30.7 137.4-.7l1.8-1z" fill="#C55ED6"/>
				 <path d="M578.2 710a10.2 10.2 0 00-.2 20.5h130.2a35.8 35.8 0 01.6 71.5h-.6v-25a10.8 10.8 0 00.3-21.5H578.2a35.2 35.2 0 01-.5-70.5h.5v25z" fill="#8EEFE7"/>
			  </g>
			</svg>
		</div>
  </header>
)

export default Header
