/**
 * Loud component
 */

import React from "react"
import "./loud.scss"

const Loud = () => {
  return (
    <div id="loud" className="home-loud">
		<h1 className="visually-hidden">TechYourChance, école à impact social qui propose des formations aux métiers du numérique à Tournai, Wallonie.</h1>
		<div className="wrapper">
			<p><span>Envie de vous faire<br/> une place dans le numérique<br/> dès demain ?</span></p>
		</div>
	</div>
  )
}

export default Loud
